<template>
    <div class="container pb-5">
        <!-- Nav com breadcrumb -->
        <div class="nav-rotas-anteriores pt-5 px-3">
            <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                    <li class="breadcrumb-item"><router-link to="/categorias">Categorias</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">{{$route.params.curso}}</li>
                </ol>
            </nav>
        </div>

        <!-- Cabeçalho da Categoria -->
        <div class="row header-categoria py-5 px-3">
            <div class="col-3 col-md-1 categoria-icon">
                <img :src="'https://www.perlego.com/images/subject_icons/' + filtra_categoria[0].icon" width="50">
            </div>
            <div class="col-8 col-md-8 text-start" style="align-self: center">
                <h1 class="categoria-title">{{filtra_categoria[0].name}}</h1>
            </div>
        </div>

        <!-- <h1>Painel para testes de solicitação </h1>
        curso: <input v-model="curso_escolhido" type="text"><br>
        start at:<input v-model="num_startAt" type="number"><br>
        limit: <input v-model="num_limit" type="number"><br>
        <button v-on:click="retornaLivrosFirebasePorCurso(curso_escolhido, num_startAt, num_limit)">Filtrar</button>
        <br>
        <button class="mx-5" v-on:click="paginaAnterior()">Página anterior</button>
        <button class="mx-5" v-on:click="proximaPagina()">Próxima página</button> -->

        <booksRowGallery :key="key_atualizacao" :targetArray="acervo"></booksRowGallery>

        <!-- Swiper -->
        <!-- <Splide :options="options" :key="'splide'+key_atualizacao">
            <Splide-slide v-for="livro in acervo" v-bind:key="livro.id" class="livro">
                <a :href="livro.link_acesso">
                    <img :src="livro.link_capa">
                </a>
            </Splide-slide>
        </Splide> -->

        <!-- Paginação -->
        <div class="row mt-5" style="place-content: center;">
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <b-button :disabled="btn_anterior" class="page-link" v-on:click="paginaAnterior()">Página anterior</b-button>
                    <b-button class="page-link" v-on:click="proximaPagina()">Próxima página</b-button>
                </ul>
            </nav>
        </div>

    </div>
</template>

<script>
// import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import booksRowGallery from '@/components/booksRowGallery.vue';
import tags_lista from '@/store/categorias.js'
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    components: {
        booksRowGallery,
        // Splide,
        // SplideSlide,
    },
    data() {
        return {
            curso_escolhido: "",
            num_startAt: 0,
            num_limit: 30,
            first_book: 0,
            last_book: 0,
            btn_anterior: "false",
            previous_start_points: [],
            acervo: [],
            key_atualizacao: 0,
            options: {
				type: 'loop',
				pagination: false,
				perPage: 8,
				perMove: 1,
				gap: '1rem',
				breakpoints: {
					0: {
						perPage: 2,
					},
					350: {
						perPage: 3,
					},
					400: {
						perPage: 4,
					},
					576: {
						perPage: 4,
					},
					768: {
						perPage: 5,
					},
					992: {
						perPage: 6,
					},
					1200: {
						perPage: 6,
					}
				}
            }
        }
    },
    computed: {
        // Aqui filtramos todo o array de Tags para encontrar o icone e o nome categoria da página
        filtra_categoria: function(){
            return tags_lista.lista.filter(x => x.path === this.$route.params.curso)
        }
    },
    methods: {
        retornaLivrosFirebasePorCurso: function(curso, partida, limite){
            console.log(curso, typeof curso, this.num_startAt, typeof this.num_startAt, this.num_limit, typeof this.num_limit)
            let respostaRaw = [];
            db.collection("acervo-completo").where("tags_area", "array-contains", curso)
            .orderBy("index")
            .startAt(parseInt(partida))
            .limit(parseInt(limite))
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    respostaRaw.push(doc.data())
                });
                this.acervo = respostaRaw
                // Atualiza o elemento. Essa key garante que o elemento inteiro seja atualizado, de forma que ele reinicie
                this.key_atualizacao++
                // Computando a próxima página
                this.first_book = parseInt(respostaRaw[0].index)
                this.last_book = parseInt(respostaRaw[respostaRaw.length-1].index)

                // Nessa variável armazenamos uma série dos primeiros index de cada pesquisa, dessa forma podemos fazer o botão de voltar
                this.previous_start_points.push(this.first_book)
                
                console.log(this.acervo)
                console.log('Primeiro item: ', this.first_book)
                console.log('Último item: ', this.last_book)
            })
            .catch((error) => {
                console.log("Não foi possivel retornar os resultados solicitados para o curso: ", curso, ". Erro: ", error);
            });
        },

        paginaAnterior: function(){
            // Pega o index de livro atual, acha o index dele no array de histórico, volta 1 valor e chama a função novamente com este valor
            console.log('SOLICITANDO PÁGINA ANTERIOR')
            console.log(this.previous_start_points)
            if(this.previous_start_points){
                let previous_point = this.previous_start_points.findIndex(x => x === this.first_book) - 1
                console.log(previous_point)
                if(previous_point === 1){
                    this.btn_anterior= false
                }
                this.retornaLivrosFirebasePorCurso(this.curso_escolhido, this.previous_start_points[previous_point], this.num_limit)
            }    
        },

        proximaPagina: function(){
            console.log('SOLICITANDO PRÓXIMA PÁGINA')
            // Repare que ultilizamos o last_book como nosso ponto de partida agora
            this.retornaLivrosFirebasePorCurso(this.curso_escolhido, this.last_book+1, this.num_limit)
            console.log('HISTÓRICO: ', this.previous_start_points)
            this.btn_anterior= true // ativa botão da página anterior
        }
    
        // retornaLivroFirebasePormmsid: function(mms_id){
        //     db.collection("acervo-completo").where("mms_id", "==", mms_id)
        //     .get()
        //     .then((doc) => {
        //         if (doc.exists) {
        //             return doc.data()
        //         } else {
        //             // doc.data() will be undefined in this case
        //             console.log("O documento socilicitado pelo mms_id: ", mms_id, " não existe");
        //         }
        //     })
        //     .catch((error) => {
        //         console.log("Não foi possivel retornar o livro solicitados para o mms_id: ", mms_id, ". Erro: ", error);
        //     });
    },
    mounted(){
        this.curso_escolhido = this.$route.params.curso

        // GAMBIARRA DE NORMATIZAÇÃO
        if (this.curso_escolhido === 'medicina' || this.curso_escolhido === "Medicina"){
            this.curso_escolhido = 'MB Medicina'
        } else if (this.curso_escolhido === 'direito' || this.curso_escolhido === "Direito") {
            this.curso_escolhido = 'MB Jurídica'
        } else if (this.curso_escolhido === 'pedagogia' || this.curso_escolhido === "Pedagogia") {
            this.curso_escolhido = 'MB Pedagógica'
        }

        this.retornaLivrosFirebasePorCurso(this.curso_escolhido, this.num_startAt, this.num_limit)
    },
}

</script>

<style scoped>
button, a {
    color: #000;
    font-weight: 600;
    text-decoration: none;
}
.breadcrumb{
    background-color: transparent;
    justify-content: left;
}
.categoria-title {
    margin: 0px !important;
}
div > .categoria-title {
    align-self: center !important;
}
.pagination {
    justify-content: center;
}
.livro {
	transition: all .3s ease-in-out;
	padding: 10px 0;
}	
.livro:hover {
	transition: all .3s ease-in-out;
	-ms-transform: scale(1.4);
	-webkit-transform: scale(1.4);  
	transform: scale(1.1);
}
.livro img {  
	border-radius: 7px;
	box-shadow: var(--shadow) 0px 3px 8px;
	width: -webkit-fill-available;
}
.livro img:hover {  
	box-shadow: 0px 1px 5px 2px var(--shadow);
}
.categoria-header {
    align-items: center;
    padding-right: 0px !important;
}
.categoria-img{
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    -o-object-fit: cover;
    /* object-fit: cover; */
    box-shadow: 0 1px 4px 0 rgb(40 29 50 / 20%);
}
.categoria-text{
    text-align: left;
}
.categoria-button{
    text-align: right;
    padding-right: 0px !important;
}
@media screen and (max-width: 500px){
    .categoria-text > h1{
        text-align: left;
        font-size: 1.25rem;
    }
    .categoria-img{
        border-radius: 50%;
        height: 1.5rem;
        width: 1.5rem;
        -o-object-fit: cover;
        object-fit: cover;
        box-shadow: 0 1px 4px 0 rgb(40 29 50 / 20%);
    }
    .btn{
        font-size: 0.6rem;
    }
}


/* BOOTSTRAP TESTE */
/* Extra Small */
@media screen and (min-width: 0px){
	.test{
		border: #32b336 7px solid !important;
	}
	.livro img{
		height: 120px;
	}
}
		/* Small */
@media screen and (min-width: 576px){
	.test{
		border: #ffa600 7px solid !important;
	}
	.livro img{
		height: 150px;
	}
}

/* Medium*/
@media screen and (min-width: 768px){
	.test{
		border: #41a7ff 7px solid !important;
	}
	.livro img{
		height: 170px;
	}
    .breadcrumb{
        justify-content: center;
    }
}

/* Large*/
@media screen and (min-width: 992px){
	.test{
		border: yellow 7px solid !important;
	}
	.livro img{
		height: 200px;
	}
}

/* Extra large */
@media screen and (min-width: 1200px){
	.test{
		border: #ff0053 7px solid !important;
	}
	.livro img{
		max-height: 200px;
	}
    .breadcrumb{
        justify-content: left;
    }
}
</style>


